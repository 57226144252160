<template>
  <div class="order_home">
    <div class="box_title">
      <div class="home_title fs-46">订单详情</div>
    </div>
    <van-cell-group>
      <van-cell title="订单状态" :value="orderInfo.order_state==0?'用户已邮寄':orderInfo.order_state==1?'亚丁已收货':orderInfo.order_state==2?'已邮寄用户':''" />
      <van-cell title="订单审核" v-if="orderInfo.order_mold == 2" :value="orderInfo.order_examine==0?'未审核':orderInfo.order_examine==1?'可换（收钱-邮寄新机）':orderInfo.order_examine==2?'不可换，原路退回':''" />
      <van-cell title="姓名" :value="orderInfo.name" />
      <van-cell title="电话" :value="orderInfo.phone" />
      <van-cell title="邮寄地址" :value="orderInfo.address" />
      <van-cell title="回寄地址" v-if="orderInfo.order_mold != 3" :value="orderInfo.user_address" />
      <template v-if="orderInfo.order_mold != 1">
        <van-cell title="支付宝账号" v-if="orderInfo.order_mold == 3" :value="orderInfo.user_account" />
        <van-cell title="活动机型" :value="orderInfo.old_model" />
        <van-cell title="活动机型内存" :value="orderInfo.old_ram" />
        <van-cell title="订单类型" :value="orderInfo.order_mold=='1'?'维修订单':orderInfo.order_mold=='2'?'以旧换新':'纯回收'" />
        <van-cell v-if="orderInfo.order_mold == 2" title="新机品牌" :value="orderInfo.new_brand" />
        <van-cell v-if="orderInfo.order_mold == 2" title="新机机型" :value="orderInfo.new_model" />
        <van-cell v-if="orderInfo.order_mold == 2" title="新机内存" :value="orderInfo.new_ram" />
        <van-cell v-if="orderInfo.order_mold == 2" title="新机颜色" :value="orderInfo.new_colour" />
        <van-cell title="活动机价格" :value="orderInfo.old_price" />
        <van-cell v-if="orderInfo.order_mold == 2" title="新机价格" :value="orderInfo.new_price" />
        <van-cell title="抵扣金额" :value="orderInfo.claims_money" />
        <van-cell v-if="orderInfo.order_mold == 2" title="补缴金额" :value="orderInfo.money" />
      </template>
    </van-cell-group>
  </div>
</template>

<script>
import { Toast } from "vant";
import { couponOrderInfo } from "@/api/couponOrder";
export default {
  name: "orderhome",
  data() {
    return {
      orderInfo: {},
    };
  },
  created() {
    let order_id = this.$route.query.orderId;
    couponOrderInfo({ order_id }).then((res) => {
      this.orderInfo = res.data;
    });
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.order_home {
  position: relative;
  width: 100vw;
  .box_title {
    padding: 40px 30px;

    font-family: PingFang SC;
    font-weight: 800;
    color: #333333;
  }
}
</style>
