import request from "@/utils/http";


//获取优惠劵信息
export function getCouponInfo(data) {
    return request({
        url: '/Coupon/getCoupon',
        method: 'post',
        data
    })
}

//优惠劵-领取激活
export function couponAct(data) {
    return request({
        url: '/Coupon/couponAct',
        method: 'post',
        data
    })
}

//优惠劵-查询
export function couponQuery(data) {
    return request({
        url: '/Coupon/couponQuery',
        method: 'post',
        data
    })
}

//邮寄地址
export function mailAddress(data) {
    return request({
        url: '/Coupon/mailAddress',
        method: 'post',
        data
    })
}

//优惠劵活动下单（ 维修 ， 以旧换新 ，纯回收 ）
export function couponOrder(data) {
    return request({
        url: '/Coupon/couponOrder',
        method: 'post',
        data
    })
}

//优惠活动订单详情
export function couponOrderInfo(data) {
    return request({
        url: '/Coupon/couponOrderInfo',
        method: 'post',
        data
    })
}

//参与活动机型
export function couponPhoneModel(data) {
    return request({
        url: '/Coupon/couponPhoneModel',
        method: 'post',
        data
    })
}

//活动机型-内存
export function couponPhoneRam(data) {
    return request({
        url: '/Coupon/couponPhoneRam',
        method: 'post',
        data
    })
}

//换机订单类型
export function orderMold(data) {
    return request({
        url: '/Coupon/orderMold',
        method: 'post',
        data
    })
}

//活动机型价格
export function couponPrice(data) {
    return request({
        url: '/Coupon/couponPrice',
        method: 'post',
        data
    })
}